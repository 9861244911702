<template>
	<div class="total drag" v-draw id="RqParameter3">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="zhuangshizb"></div>
				<div class="zhuangshizj">{{projectData.project}}_参数3</div>
				<div class="zhuangshiyb" @click="closeCompon">
					<div class="icon" @click="closeCompon"></div>
				</div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				引风优化控制模型
			</div>
		</div>
		<!-- 内容 -->
		<div class="content flex">
			<div class="one">
				<div class="shadow flex">
					<div class="column1_words">E03-SP</div>
					<div class="column1_num1" @click="toIpt(infoList.MCYINFENG.FYSP,'E03-SP','FYSP','MCYINFENG')">
						{{infoList.MCYINFENG.FYSP}}
					</div>
				</div>
				<div class="table table1">
					<div class="table_line flex">
						<div class="table_column1">E02-1</div>
						<div class="table_column2" @click="toCompon(2,'PICA_107_B','MCSOUREC','PICA_107_NG3','炉膛负压1')"
							@dblclick="Cclick(infoList.MCSOUREC.PICA_107_B,'PICA_107_B','MCSOUREC','PICA_107_NG3')">
							{{infoList.MCSOUREC.PICA_107_B}}
						</div>
					</div>
					<div class="table_line flex">
						<div class="table_column1">E02-2</div>
						<div class="table_column2" @click="toCompon(2,'PICA_108_B','MCSOUREC','PICA_108_NG3','炉膛负压2')"
							@dblclick="Cclick(infoList.MCSOUREC.PICA_108_B,'PICA_108_B','MCSOUREC','PICA_108_NG3')">
							{{infoList.MCSOUREC.PICA_108_B}}
						</div>
					</div>
					<div class="table_line flex">
						<div class="table_column1">E02-3</div>
						<div class="table_column2" @click="toCompon(2,'PICA_109_B','MCSOUREC','PICA_109_NG3','炉膛负压3')"
							@dblclick="Cclick(infoList.MCSOUREC.PICA_109_B,'PICA_109_B','MCSOUREC','PICA_109_NG3')">
							{{infoList.MCSOUREC.PICA_109_B}}
						</div>
					</div>
					<div class="table_line flex">
						<div class="table_column1">E02-4</div>
						<div class="table_column2" @click="toCompon(2,'PICA_110_B','MCSOUREC','PICA_110_NG3','炉膛负压4')"
							@dblclick="Cclick(infoList.MCSOUREC.PICA_110_B,'PICA_110_B','MCSOUREC','PICA_110_NG3')">
							{{infoList.MCSOUREC.PICA_110_B}}
						</div>
					</div>
					<div class="table_line flex">
						<div class="table_column1">E02-C</div>
						<div class="table_column3 " @click="toIpt(infoList.MCYINFENG.FY_C,'E02-C','FY_C','MCYINFENG')">
							{{infoList.MCYINFENG.FY_C}}
						</div>
					</div>
					<div class="table_line flex">
						<div class="table_column1">E02-T</div>
						<div class="table_column3" @click="toIpt(infoList.MCYINFENG.FYTC,'E02-T','FYTC','MCYINFENG')">
							{{infoList.MCYINFENG.FYTC}}
						</div>
					</div>
					<div class="table_line flex">
						<div class="table_column1">E02-7</div>
						<div class="table_column2" @click="toCompon(2,'FYPV','MCYINFENG','FYPV_NG3','负压测量值')"
							@dblclick="Cclick(infoList.MCYINFENG.FYPV,'FYPV','MCYINFENG','FYPV_NG3')">
							{{infoList.MCYINFENG.FYPV}}
						</div>
					</div>
				</div>
				<div class="shadow line7 flex">
					<div class="column1_words">E03-SP</div>
					<div class="column1_num1" @click="toIpt(infoList.MCYINFENG.FYSP,'E03-SP','FYSP','MCYINFENG')">
						{{infoList.MCYINFENG.FYSP}}
					</div>
				</div>
			</div>
			<div class="two flex">
				<div>
					<div class="bg_line flex">
						<div class="button button1" :style="{
								background:infoList.MCXK__p__XK4 && !infoList.MCXK__p__XK4.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCXK__p__XK4
								? toDetail(1, 'XK4', 'MCXK__p__XK4', '', '引风变频先控调节'): ''">
							X
						</div>
						<div class="shadow shadow_location flex">
							<div class="column1_words">E03-AV</div>
							<div class="column1_num" @click="toCompon(2,'AV','MCXK__p__XK4','XK4_AV_NG3','引风变频先控调节')"
								@dblclick="Cclick(infoList.MCXK__p__XK4.AV,'AV','MCXK__p__XK4','XK4_AV_NG3')">
								{{infoList.MCXK__p__XK4.AV}}
							</div>
						</div>
					</div>
					<div class="bg_line1 flex">
						<div class="table2">
							<div class="table_title">引风挡板--负压系数</div>
							<div class="table_total">
								<div class="table_line flex">
									<div class="table_column3">挡板开度</div>
									<div class="table_column4 words">系数</div>
								</div>
								<div class="table_line flex">
									<div class="table_column3 flex">
										<div class="zero1"></div>
										<div class="mark">
											<=< 
										</div>
										<div class="zero2"
											@click="toIpt(infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__1__rr__,'引风挡板开度','IN_FD__ll__1__rr__','MCYINFENG__p__YFDBFD')">
											{{infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__1__rr__}}
										</div>
										</div>
										<div class="table_column4"
											@click="toIpt(infoList.MCYINFENG__p__YFDBFD.AV_FD__ll__1__rr__,'引风挡板系数','AV_FD__ll__1__rr__','MCYINFENG__p__YFDBFD')">
											{{infoList.MCYINFENG__p__YFDBFD.AV_FD__ll__1__rr__}}
										</div>
									</div>
									<div class="table_line flex">
										<div class="table_column3 flex">
											<div class="zero1"
												@click="toIpt(infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__1__rr__,'引风挡板开度','IN_FD__ll__1__rr__','MCYINFENG__p__YFDBFD')">
												{{infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__1__rr__}}
											</div>
											<div class="mark">---</div>
											<div class="zero2"
												@click="toIpt(infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__2__rr__,'引风挡板开度','IN_FD__ll__2__rr__','MCYINFENG__p__YFDBFD')">
												{{infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__2__rr__}}
											</div>
										</div>
										<div class="table_column4"
											@click="toIpt(infoList.MCYINFENG__p__YFDBFD.AV_FD__ll__2__rr__,'引风挡板系数','AV_FD__ll__2__rr__','MCYINFENG__p__YFDBFD')">
											{{infoList.MCYINFENG__p__YFDBFD.AV_FD__ll__2__rr__}}
										</div>
									</div>
									<div class="table_line flex">
										<div class="table_column3 flex">
											<div class="zero1"
												@click="toIpt(infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__2__rr__,'引风挡板开度','IN_FD__ll__2__rr__','MCYINFENG__p__YFDBFD')">
												{{infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__2__rr__}}
											</div>
											<div class="mark">---</div>
											<div class="zero2"
												@click="toIpt(infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__3__rr__,'引风挡板开度','IN_FD__ll__3__rr__','MCYINFENG__p__YFDBFD')">
												{{infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__3__rr__}}
											</div>
										</div>
										<div class="table_column4"
											@click="toIpt(infoList.MCYINFENG__p__YFDBFD.AV_FD__ll__3__rr__,'引风挡板系数','AV_FD__ll__3__rr__','MCYINFENG__p__YFDBFD')">
											{{infoList.MCYINFENG__p__YFDBFD.AV_FD__ll__3__rr__}}
										</div>
									</div>
									<div class="table_line flex">
										<div class="table_column3 flex">
											<div class="zero1"
												@click="toIpt(infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__3__rr__,'引风挡板开度','IN_FD__ll__3__rr__','MCYINFENG__p__YFDBFD')">
												{{infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__3__rr__}}
											</div>
											<div class="mark">---</div>
											<div class="zero2"
												@click="toIpt(infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__4__rr__,'引风挡板开度','IN_FD__ll__4__rr__','MCYINFENG__p__YFDBFD')">
												{{infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__4__rr__}}
											</div>
										</div>
										<div class="table_column4"
											@click="toIpt(infoList.MCYINFENG__p__YFDBFD.AV_FD__ll__4__rr__,'引风挡板系数','AV_FD__ll__4__rr__','MCYINFENG__p__YFDBFD')">
											{{infoList.MCYINFENG__p__YFDBFD.AV_FD__ll__4__rr__}}
										</div>
									</div>
									<div class="table_line flex">
										<div class="table_column3 flex">
											<div class="zero1"
												@click="toIpt(infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__4__rr__,'引风挡板开度','IN_FD__ll__4__rr__','MCYINFENG__p__YFDBFD')">
												{{infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__4__rr__}}
											</div>
											<div class="mark">>=</div>
											<div class="zero2"
												@click="toIpt(infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__4__rr__,'引风挡板开度','IN_FD__ll__4__rr__','MCYINFENG__p__YFDBFD')">
												{{infoList.MCYINFENG__p__YFDBFD.IN_FD__ll__4__rr__}}
											</div>
										</div>
										<div class="table_column4"
											@click="toIpt(infoList.MCYINFENG__p__YFDBFD.AV_FD__ll__5__rr__,'引风挡板系数','AV_FD__ll__5__rr__','MCYINFENG__p__YFDBFD')">
											{{infoList.MCYINFENG__p__YFDBFD.AV_FD__ll__5__rr__}}
										</div>
									</div>
								</div>
							</div>
							<div class="button button2" :style="{
								background:infoList.MCQK__p__QK2 && !infoList.MCQK__p__QK2.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCQK__p__QK2
								    ? toDetail(2,'QK2','MCQK__p__QK2','','引风前馈调节'): ''">
								K
							</div>
						</div>
						<div class="bg_line2 flex">
							<div class="button button1" :style="{
								background:infoList.MCXK__p__XK5 && !infoList.MCXK__p__XK5.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCXK__p__XK5
								? toDetail(1, 'XK5', 'MCXK__p__XK5', '', '引风挡板先控调节'): ''">
								X
							</div>
							<div class="shadow shadow_location flex">
								<div class="column1_words">E03-AV</div>
								<div class="column1_num"
									@click="toCompon(2,'AV','MCXK__p__XK5','XK5_AV_NG3','引风挡板先控调节')"
									@dblclick="Cclick(infoList.MCXK__p__XK5.AV,'AV','MCXK__p__XK5','XK5_AV_NG3')">
									{{infoList.MCXK__p__XK5.AV}}
								</div>
							</div>
						</div>
					</div>
					<div class="serial_number">
						<div class="serial_number1">
							<div class="numbers">BPPZ1</div>
							<div class="shadow" @click="toCompon(2,'YFBPPZ1','MCYINFENG','YFBPPZ1_NG3','1#引风机变频偏值')"
								@dblclick="Cclick(infoList.MCYINFENG.YFBPPZ1,'YFBPPZ1','MCYINFENG','YFBPPZ1_NG3')">
								{{infoList.MCYINFENG.YFBPPZ1}}
							</div>
						</div>
						<div class="serial_number2">
							<div class="numbers">BPPZ2</div>
							<div class="shadow" @click="toCompon(2,'YFBPPZ2','MCYINFENG','YFBPPZ2_NG3','2#引风机变频偏值')"
								@dblclick="Cclick(infoList.MCYINFENG.YFBPPZ2,'YFBPPZ2','MCYINFENG','YFBPPZ2_NG3')">
								{{infoList.MCYINFENG.YFBPPZ2}}
							</div>
						</div>
						<div class="serial_number3">
							<div class="numbers">DBPZ1</div>
							<div class="shadow" @click="toCompon(2,'YFDBPZ1','MCYINFENG','YFDBPZ1_NG3','1#引风机档板偏值')"
								@dblclick="Cclick(infoList.MCYINFENG.YFDBPZ1,'YFDBPZ1','MCYINFENG','YFDBPZ1_NG3')">
								{{infoList.MCYINFENG.YFDBPZ1}}
							</div>
						</div>
						<div class="serial_number4">
							<div class="numbers">DBPZ2</div>
							<div class="shadow" @click="toCompon(2,'YFDBPZ2','MCYINFENG','YFDBPZ2_NG3','2#引风机挡板偏值')"
								@dblclick="Cclick(infoList.MCYINFENG.YFDBPZ2,'YFDBPZ2','MCYINFENG','YFDBPZ2_NG3')">
								{{infoList.MCYINFENG.YFDBPZ2}}
							</div>
						</div>
					</div>
				</div>
				<div class="three">
					<div class="three_line1 flex">
						<div class="button button1" :style="{
					     	background:infoList.MCMAN__p__MAN7.RM && infoList.MCMAN__p__MAN7.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN7
					     	? toCompon(0,'AV','MCMAN__p__MAN7','MAN7_AV_NG3','1#引风机变频手操器MAN7','KF_107_B','MCSOUREC','KF_107_NG3'): ''">
							A
						</div>
						<div class="shadow shadow_location flex">
							<div class="column1_words">E03-A01</div>
							<div class="column1_num"
								@click="toCompon(2,'AV','MCMAN__p__MAN7','MAN7_AV_NG3','1#引风机变频手操器')"
								@dblclick="Cclick(infoList.MCMAN__p__MAN7.AV,'AV','MCMAN__p__MAN7','MAN7_AV_NG3')">
								{{infoList.MCMAN__p__MAN7.AV}}
							</div>
						</div>
					</div>
					<div class="three_line2 flex">
						<div class="button button1" :style="{
					     	background:infoList.MCMAN__p__MAN8.RM && infoList.MCMAN__p__MAN8.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN8
					     	? toCompon(0,'AV','MCMAN__p__MAN8','MAN8_RM_NG3','2#引风机变频手操器MAN8','KF_108_B','MCSOUREC','KF_108_NG3'): ''">
							A
						</div>
						<div class="shadow shadow_location flex">
							<div class="column1_words">E03-A02</div>
							<div class="column1_num"
								@click="toCompon(2,'AV','MCMAN__p__MAN8','MAN8_AV_NG3','2#引风机变频手操器')"
								@dblclick="Cclick(infoList.MCMAN__p__MAN8.AV,'AV','MCMAN__p__MAN8','MAN8_AV_NG3')">
								{{infoList.MCMAN__p__MAN8.AV}}
							</div>
						</div>
					</div>
					<div class="three_line3 flex">
						<div class="button button1" :style="{
					     	background:infoList.MCMAN__p__MAN9.RM && infoList.MCMAN__p__MAN9.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN9
					     	? toCompon(0,'AV','MCMAN__p__MAN9','MAN9_RM_NG3','1#引风机挡板手操器MAN9','KF_109_B','MCSOUREC','KF_109_NG3'): ''">
							A
						</div>
						<div class="shadow shadow_location flex">
							<div class="column1_words">E04-A01</div>
							<div class="column1_num"
								@click="toCompon(2,'AV','MCMAN__p__MAN9','MAN9_AV_NG3','1#引风机档板手操器')"
								@dblclick="Cclick(infoList.MCMAN__p__MAN9.AV,'AV','MCMAN__p__MAN9','MAN9_AV_NG3')">
								{{infoList.MCMAN__p__MAN9.AV}}
							</div>
						</div>
					</div>
					<div class="three_line4 flex">
						<div class="button button1" :style="{
					     	background:infoList.MCMAN__p__MAN10.RM && infoList.MCMAN__p__MAN10.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN10
					     	? toCompon(0,'AV','MCMAN__p__MAN10','MAN10_RM_NG3','2#引风机挡板手操器MAN10','KF_110_B','MCSOUREC','KF_110_NG3'): ''">
							A
						</div>
						<div class="shadow shadow_location flex">
							<div class="column1_words">E04-A02</div>
							<div class="column1_num"
								@click="toCompon(2,'AV','MCMAN__p__MAN10','MAN10_AV_NG3','2#引风机档板手操器')"
								@dblclick="Cclick(infoList.MCMAN__p__MAN10.AV,'AV','MCMAN__p__MAN10','MAN10_AV_NG3')">
								{{infoList.MCMAN__p__MAN10.AV}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- explain -->
			<div class="explain">
				<div class="explain_content">
					<div class="explain1">
						说明1：各个阀门的SADD：投入自动的变频或阀门均差1达到上限或者其中一个风机电流达到上限
					</div>
					<div class="explain2 flex">
						<div class="blank"></div>
						<div>SSUB：投入自动的变频或阀门均差1达到下限</div>
					</div>
				</div>
			</div>
			<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
			<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node'
				:Lkname='Lkname' :chName="chName" :infoList='infoList'></Historical>
			<Manual :key="isIndex" v-if="isMshow" @sendStatus="isMshow = false" :historyname="Manualname"
				:node="Manualnode" :Lkname="ManualAname" :titname="Manualtitname" :pmark="Panualmark"
				:pnode="Panualnode" :pnm="Panualnodem" :infoList="infoList">
			</Manual>
		</div>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},
		components: {
			inputVal,
			Historical,
			Manual
		},
		data: () => {
			return {
				chName: '',
				Panualmark: '',
				Panualnode: '',
				Panualnodem: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
		},
		methods: {
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'rqkfirstcontol'
							break
						case 3:
							pathname = 'RqRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Param03', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
				}
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},
			toCompon(key, name, name2, name3, name4, titname, param1, param2, param3) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							console.log(1)
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname, this.Panualmark = param1, this
								.Panualnode = param2, this.Panualnodem = param3
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.total {
		width: 53vw;
		height: 67vh;
		background-image: url("~@/assets/images/RqBoiler/servive_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -33.5vh 0 0 -26.5vw;
		z-index: 999;

		.title-hang {
			width: 53vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
		}

		.zhuangshizb {
			width: 16vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-top: 2vh;
		}

		.zhuangshizj {
			width: 19vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 1.3vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			margin-left: 1vw;
		}

		.zhuangshiyb {
			width: 16vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 1vw;
			margin-top: 2vh;
		}

		.icon {
			width: 2vw;
			height: 3.4vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 13vw;
			margin-top: -0.5vh;
			cursor: pointer;
		}

		.main {
			width: 53vw;
			height: 2vh;
			margin-top: 3vh;

			.main_title {
				font-family: PingFang-SC-Regular;
				font-size: 1.7vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.content {
			width: 50vw;
			height: 37vh;
			margin-top: 6vh;
			margin-left: 1.5vw;

			.shadow {
				width: 8vw;
				height: 2.5vh;
				background-color: #001b2860;
				box-shadow: 0vw 0vw 0vw 0vw #15384c;
				border-radius: 0vw;
				border: 1px solid rgba(0, 228, 255, .2);
				box-sizing: border-box;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 0vh;
			}

			.column1_words {
				width: 4.2vw;
				color: #8aeaff;
				text-align: center;
			}

			.column1_num {
				width: 4vw;
				color: #00ffb4;
				text-align: center;
				cursor: pointer;

			}

			.column1_num1 {
				width: 4vw;
				color: #2fc3e3;
				text-align: center;
				cursor: pointer;
			}

			.table1 {
				margin-top: 4vh;
			}

			.table {
				width: 10vw;
				height: 17vh;
				border: 1px solid rgba(0, 228, 255, .2);
				box-sizing: border-box;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 0vh;

				.table_line {
					height: 2.4vh;
					border-bottom: 1px solid rgba(0, 228, 255, .2);
					box-sizing: border-box;

					.table_column1 {
						width: 5vw;
						text-align: center;
						color: #8aeaff;
						cursor: pointer;
					}

					.table_column2 {
						border-left: 1px solid rgba(0, 228, 255, .2);
						width: 5vw;
						text-align: center;
						color: #00ffb4;
						cursor: pointer;
					}

					.table_column3 {
						border-left: 1px solid rgba(0, 228, 255, .2);
						width: 5vw;
						text-align: center;
						color: #2fc3e3;
						cursor: pointer;
					}
				}
			}

			.line7 {
				margin-top: 6vh;
			}

			.one {
				margin-top: 0.8vh;
			}

			.two {
				width: 33vw;
				height: 37vh;
				background-image: url("~@/assets/images/RqBoiler/para3.png");
				background-size: 100% 100%;
				margin-left: -2vw;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 0vh;

				.button {
					width: 1vw;
					height: 2vh;
					border: 2px solid rgb(217, 243, 145);
					text-align: center;
					line-height: 2vh;
					background-color: #00e4ff;
					color: white;
					cursor: pointer;
				}

				.bg_line {
					margin-left: 3vw;
					margin-top: 0.8vh;

					.shadow_location {
						margin-left: 4.1vw;
					}
				}

				.bg_line1 {
					.table2 {
						margin-left: 7vw;
						margin-top: 4vh;

						.table_title {
							width: 10vw;
							text-align: center;
							color: #8aeaff;
						}

						.table_total {
							width: 10vw;
							height: 14vh;
							border: 1px solid rgba(0, 228, 255, .2);
							box-sizing: border-box;
							font-family: PingFang-SC-Regular;
							font-size: 1vw;
							font-weight: normal;
							font-stretch: normal;
							line-height: 2vh;
							letter-spacing: 0vh;

							.table_line {
								height: 2.32vh;
								border-bottom: 1px solid rgba(0, 228, 255, .2);
								box-sizing: border-box;

								.table_column3 {
									width: 7vw;
									color: #2fc3e3;
									text-align: center;
									cursor: pointer;

									.zero1 {
										width: 1.75vw;
										color: #2fc3e3;
										text-align: center;
									}

									.mark {
										width: 3vw;
										color: #8aeaff;
										text-align: center;
									}

									.zero2 {
										width: 1.75vw;
										color: #2fc3e3;
										text-align: center;
									}
								}

								.table_column4 {
									width: 3vw;
									text-align: center;
									border-left: 1px solid rgba(0, 228, 255, .2);
									color: #2fc3e3;
									cursor: pointer;
								}

								.words {
									color: #2fc3e3;
								}
							}
						}
					}

					.button2 {
						margin-top: 11vh;
						margin-left: 5vw;
						background-color: #04ff57;
						cursor: pointer;
					}
				}

				.bg_line2 {
					margin-left: 3vw;
					margin-top: 7vh;

					.shadow_location {
						margin-left: 4.1vw;
					}
				}

				.serial_number {
					.numbers {
						width: 5.5vw;
						color: #8aeaff;
						text-align: right;
					}

					.shadow {
						width: 5vw;
						height: 2.5vh;
						background-color: #001b2860;
						box-shadow: 0vw 0vw 0vw 0vw #15384c;
						border-radius: 0vw;
						border: 1px solid rgba(0, 228, 255, .2);
						box-sizing: border-box;
						font-family: PingFang-SC-Regular;
						font-size: 1vw;
						font-weight: normal;
						font-stretch: normal;
						line-height: 2vh;
						letter-spacing: 0vh;
						text-align: center;
						color: #00ffb4;
					}

					.serial_number1 {
						margin-left: 4.2vw;
						margin-top: 4.2vh;
						cursor: pointer;
					}

					.serial_number2 {
						margin-left: 4.2vw;
						margin-top: 5.7vh;
						cursor: pointer;
					}

					.serial_number3 {
						margin-left: 4.2vw;
						margin-top: 5.7vh;
						cursor: pointer;
					}

					.serial_number4 {
						margin-left: 4.2vw;
						margin-top: 5.7vh;
						cursor: pointer;
					}
				}
			}

			.three {
				.button {
					width: 1vw;
					height: 2vh;
					border: 2px solid rgb(217, 243, 145);
					text-align: center;
					line-height: 2vh;
					background-color: #00e4ff;
					color: white;
				}

				.shadow {
					width: 8vw;
					height: 2.5vh;
					background-color: #001b2860;
					box-shadow: 0vw 0vw 0vw 0vw #15384c;
					border-radius: 0vw;
					border: 1px solid rgba(0, 228, 255, .2);
					box-sizing: border-box;
					font-family: PingFang-SC-Regular;
					font-size: 1vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 2vh;
					letter-spacing: 0vh;
					text-align: center;
					color: #00ffb4;
				}

				.three_line1 {
					margin-top: 0.8vh;
					cursor: pointer;
				}

				.three_line2 {
					margin-top: 7.2vh;
					cursor: pointer;
				}

				.three_line3 {
					margin-top: 7.4vh;
					cursor: pointer;
				}

				.three_line4 {
					margin-top: 7.2vh;
					cursor: pointer;
				}
			}
		}

		.explain {
			width: 53vw;
			height: 12vh;
			margin-top: 3vh;
			border-top: 2px solid #bbd6e5;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #4d91a7;

			.explain_content {
				margin-top: 2vh;
				margin-left: 2vw;

				.explain2 {
					.blank {
						width: 8.9vw;
					}
				}
			}
		}

	}
</style>
